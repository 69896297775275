import React from 'react'
import { Col, Container, Row } from 'react-grid-system'

import addresses from '../../../../data/footer'
import styles from './styles.module.scss'

const OfficeLocation = () => {
  return (
    <div className={styles.office}>
      <Container
        className={styles.office_container}
        style={{
          maxWidth: 1000,
          paddingTop: 180,
          paddingBottom: 180,
        }}
      >
        <Row>
          <Col md={5}>
            <h3 className={styles.office_location_header}>Office locations</h3>
          </Col>
          <Col md={7}>
            {addresses.col1.map((address) => {
              return (
                <Row key={address.country + address.line1} className={styles.office_location}>
                  <Col
                    md={4}
                    style={{
                      paddingLeft: 0,
                    }}
                  >
                    <h5>{address.country}</h5>
                  </Col>
                  <Col md={8}>
                    <p>
                      {address.line1} {address.line2}
                    </p>
                  </Col>
                  <hr />
                </Row>
              )
            })}
          </Col>
        </Row>
        <Row>
          <span />
        </Row>
        <Row>
          <span />
        </Row>
      </Container>
    </div>
  )
}

export default OfficeLocation
