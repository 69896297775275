import React from 'react'
import { Col, Row } from 'react-grid-system'

import styles from './styles.module.scss'

const BottomFooter = () => {
  return (
    <Row className={styles.footer_mim}>
      <Col sm={12} md={12} xs={12} className={styles.section}>
        <div className={styles.bottom_bar}>
          <p>
            <span
              color="white"
              style={{
                top: 0.5,
                position: 'relative',
              }}
            >
              <img src={require('../../../../../public/images/copyr.svg')} alt="" />
            </span>{' '}
            {new Date().getFullYear()}, Urus Advisory Limited. All rights reserved.
          </p>
          <div className={styles.links}>
            <a href="/#/privacy" target={'_blank'}>
              Privacy Policy
            </a>
            <a href="/#/cookies" target={'_blank'}>
              Cookie Policy
            </a>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default BottomFooter
