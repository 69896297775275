import React from 'react'
import { Link } from 'react-router-dom'

import styles from './styles.module.scss'

const MiniNavBar = () => {
  return (
    <div className={styles.navbar__mini}>
      <div className={styles.image}>
        <Link to={'/'}>
          <img src={require('../../../../public/images/logo.svg')} alt="" />
        </Link>
      </div>
    </div>
  )
}

export default MiniNavBar
