import axios from 'axios'

const ROOT = 'https://api.pipedrive.com/v1/'
const PERSONS_PATH = 'persons'
const LEADS_PATH = 'leads'
const ORGANIZATION_PATH = 'organizations'
const NOTE_PATH = 'notes'

// const API_KEY = '46f55eea9886103a1a16e50de781beba54a2bd09'
const API_KEY = 'a499072eb0f108d8f3be6c90cd9ba1fef33ad505'

export const getCustomFields = async () => {
  const response = await axios.get(ROOT + PERSONS_PATH + '?api_token=' + API_KEY)
  return response
}

type CreateUserType = {
  name: string
  phone?: string
  email: string
  marketing_status?: string
}

export const createProfile = async ({ name, phone, email, marketing_status }: CreateUserType) => {
  const data = {
    name,
    email: [
      {
        value: email,
        primary: 'true',
        label: null,
      },
    ],
    phone: phone
      ? [
          {
            value: phone,
            primary: 'true',
            label: null,
          },
        ]
      : null,
    '570b22175d833b8f98e9c5322001ea4790ce2a5f': marketing_status,
  }

  const response = await axios.post(ROOT + PERSONS_PATH + '?api_token=' + API_KEY, data)

  return response.data.data
}

type CreateLead = {
  title: string
  person_id: number
  organization_id?: number
}

export const createLead = async ({ title, person_id, organization_id }: CreateLead) => {
  const data = {
    title,
    person_id,
    organization_id,
  }

  const response = await axios.post(ROOT + LEADS_PATH + '?api_token=' + API_KEY, data)

  return response?.data?.data
}

type CreateOrg = {
  name: string
  owner_id: number
}

export const createOrg = async ({ name, owner_id }: CreateOrg) => {
  const data = {
    name,
    owner_id,
  }
  const response = await axios.post(ROOT + ORGANIZATION_PATH + '?api_token=' + API_KEY, data)

  return response?.data?.data
}

type CreateNote = {
  content: string
  lead_id: string
}

export const createNotes = async ({ content, lead_id }: CreateNote) => {
  const data = {
    content,
    lead_id,
  }
  const response = await axios.post(ROOT + NOTE_PATH + '?api_token=' + API_KEY, data)

  return response?.data?.data as unknown as { id: number }
}
