import React from 'react'
import { Container, Row, Col } from 'react-grid-system'

import styles from './styles.module.scss'

const Leads = () => {
  return (
    <div className={styles.leads}>
      <Container>
        <Row>
          <Col>
            <h3>Project Leads</h3>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className={styles.item}>
            <div className={styles.lead}>
              <img src={require('../../../../../public/images/lead_1.png')} />
              <h4>Alexander Teddy</h4>
              <p>Managing Director and Head of Investigations</p>
            </div>
          </Col>
          <Col sm={4} className={styles.item}>
            <div className={styles.lead}>
              <img src={require('../../../../../public/images/lead_2.png')} />
              <h4>Alexei Panin</h4>
              <p>Managing Director</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Leads
