import React, { useMemo, useState } from 'react'
import { Marker } from 'react-simple-maps'

import styles from './styles.module.scss'

const CustomMarker = ({
  label,
  cords = [0, 0],
}: {
  label: string | number
  cords?: [number, number]
  hover?: boolean
}) => {
  const [hovered, setHovered] = useState(false)

  const handleMouseEnter = () => {
    setHovered(true)
  }

  const handleMouseLeave = () => {
    setHovered(false)
  }

  const size = useMemo(() => {
    return 3.5 * (label + '').length * (hovered ? 1.15 : 1)
  }, [label, hovered])
  return (
    <Marker
      className={`${styles.animatedMarker}`}
      coordinates={[cords[0] - 2, cords[1] - 2]}
      fill="#777"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <circle
        cx={10}
        cy={0}
        style={{
          transition: 'r 0.3s ease-in-out',
        }}
        r={`${size}`}
        fill={'#EC5922'}
      />
      <text
        x="10"
        y="1.5"
        textAnchor="middle"
        alignmentBaseline="middle"
        fontWeight={300}
        fontFamily="Inter"
        fontSize="9"
        fill="#fff"
      >
        {label}
      </text>
    </Marker>
  )
}

export default CustomMarker
