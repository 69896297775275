import React, { useEffect } from 'react'
import { Container, Row } from 'react-grid-system'
import Modal from 'react-modal'

const SuccessModal = ({ isAutoClose = true, handleModal = (_: boolean) => {}, isOpen = false }) => {
  useEffect(() => {
    if (isOpen && isAutoClose) {
      setTimeout(() => {
        handleModal(false)
      }, 3000)
    }
  }, [isOpen, isAutoClose])

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      closeTimeoutMS={3000}
      style={{
        overlay: {
          backdropFilter: 'blur(3px)',
          background: 'rgba(255, 255, 255, 0.5',
          zIndex: 2,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          padding: '40px 30px',
          transform: 'translate(-50%, -50%)',
          background: 'none',
          border: 'none',
        },
      }}
    >
      <Container>
        <Row
          style={{
            alignItems: 'center',
            gap: 25,
            flexDirection: 'column',
          }}
        >
          <img
            style={{
              width: 60,
              height: 60,
              margin: '0 auto',
            }}
            src={require('../../../../public/images/check.svg')}
            alt=""
          />
          <p
            style={{
              fontWeight: 700,
              marginLeft: 10,
              fontSize: '16pt',
              fontFamily: 'Poppins',
            }}
          >
            Many thanks for your query. We will get in touch soon!
          </p>
        </Row>
      </Container>
    </Modal>
  )
}

export default SuccessModal
