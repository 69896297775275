import React from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

import styles from './styles.module.scss'

const Checkbox = ({
  onChange,
  name,
  id,
  label,
  register,
  required = true,
  isError = false,
}: {
  register?: UseFormRegister<FieldValues>
  isError?: boolean
  value?: string
  required?: boolean
  checked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  name: string
  id?: string
  label?: string | JSX.Element
  disabled?: boolean
}) => {
  return (
    <div className={styles.input}>
      <input
        id={id}
        type="checkbox"
        name={name}
        onChange={onChange}
        {...register?.(name, {
          required,
        })}
      />
      <label htmlFor={id} className={isError ? styles.error : styles.label}>
        {label}
      </label>
    </div>
  )
}
export default Checkbox
