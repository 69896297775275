import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'

import Footer from '../Home/components/Footer/Footer'
import Meet from './component/Meet/Meet'

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // @ts-ignore
      behavior: 'instant',
    })
  }, [])

  const componentRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  return (
    <div id="about" ref={componentRef}>
      <Meet />
      <Footer isMinimized={false} />
    </div>
  )
}

export default About
