export const pages = [
  '/',
  '/services',
  '/asset-tracing',
  '/corporate-investigations',
  '/strategic-political-risk',
  '/supply-chains-sanctions',
  '/about',
  '/contact',
]
