import React, { createRef, useEffect, useMemo } from 'react'
import ReactGA from 'react-ga4'
import { setConfiguration } from 'react-grid-system'
import TagManager from 'react-gtm-module'
import { RouterProvider, createHashRouter } from 'react-router-dom'

import About from './pages/About/About'
import AssetTracing from './pages/AssetsTracing/AssetsTracing'
import CaseStudies from './pages/CaseStudies/CaseStudies'
import Contact from './pages/Contact/Contact'
import Cookies from './pages/Cookies/Cookies'
import CorporateInvestigations from './pages/CorporateInvestigations/CorporateInvestigations'
import Home from './pages/Home/Home'
import NotFound from './pages/NoteFound/NotFound'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import Services from './pages/Service/Services'
import StrategicPoliticalRiskAdvisory from './pages/StrategicPoliticalRiskAdvisory/StrategicPoliticalRiskAdvisory'
import SupplyChainsSanctionsCompliance from './pages/SupplyChainsSanctionsCompliance/SupplyChainsSanctionsCompliance'
import SiteMap from './pages/sitemap'
import './reset-bootstrap.scss'
import Layout from './shared/components/Layout/Layout'
import styles from './styles.module.scss'

const App = () => {
  setConfiguration({ containerWidths: [540, 740, 960, 1200, 1200, 1810] })

  const routes = useMemo(
    () => [
      {
        path: '/',
        element: <Home />,
        nodeRef: createRef<HTMLDivElement>(),
      },
      {
        path: '/about',
        element: <About />,
        nodeRef: createRef<HTMLDivElement>(),
      },
      {
        path: '/services',
        element: <Services />,
        nodeRef: createRef<HTMLDivElement>(),
      },
      {
        path: '/contact',
        element: <Contact />,
        nodeRef: createRef<HTMLDivElement>(),
      },
      {
        path: '/case-studies',
        element: <CaseStudies />,
        nodeRef: createRef<HTMLDivElement>(),
      },
      {
        path: '/strategic-political-risk',
        element: <StrategicPoliticalRiskAdvisory />,
        nodeRef: createRef<HTMLDivElement>(),
      },
      {
        path: '/supply-chains-sanctions',
        element: <SupplyChainsSanctionsCompliance />,
        nodeRef: createRef<HTMLDivElement>(),
      },
      {
        path: '/corporate-investigations',
        element: <CorporateInvestigations />,
        nodeRef: createRef<HTMLDivElement>(),
      },
      {
        path: '/asset-tracing',
        element: <AssetTracing />,
        nodeRef: createRef<HTMLDivElement>(),
      },
    ],
    []
  )

  useEffect(() => {
    ReactGA.initialize('G-0D7TQFYMB6')
    TagManager.initialize({
      gtmId: 'GTM-54K72LCM',
    })
  }, [])

  const router = createHashRouter([
    {
      element: <Layout routes={routes} />,
      children: [...routes],
    },
    {
      path: '/cookies',
      element: <Cookies />,
    },
    {
      path: '/privacy',
      element: <PrivacyPolicy />,
    },
    {
      path: '/sitemap',
      element: <SiteMap />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ])

  return (
    <div className={styles.test}>
      <RouterProvider router={router} />
    </div>
  )
}

export default App
