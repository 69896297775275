import React, { useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { useForm } from 'react-hook-form'
import { TailSpin } from 'react-loader-spinner'

import Checkbox from '../../../../shared/components/Checkbox/Checkbox'
import Input from '../../../../shared/components/Input/Input'
import SuccessModal from '../../../../shared/components/SuccessModal/SuccessModal'
import { createLead, createNotes, createOrg, createProfile } from '../../../../utils/api'
import styles from './styles.module.scss'

const Form = ({ afterSubmit }: { afterSubmit?: () => void }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = useForm()
  const [isLoad, setIsLoad] = useState(false)

  const onSubmit = async () => {
    setIsLoad(true)
    const first = getValues('firstName')
    const isSubscribed: boolean = getValues('checkbox1')

    const last: string = getValues('lastName')
    const email: string = getValues('mail')
    const phone: string = getValues('phone')

    const profile = await createProfile({
      name: `${first} ${last}`,
      email,
      ...(phone ? { phone } : {}),
      ...(isSubscribed ? { marketing_status: 'true' } : {}),
    })

    const orgName: string = getValues('org')
    const owner_id = profile?.id
    const organization = await createOrg({
      owner_id,
      name: orgName,
    })

    const lead = await createLead({
      title: `${first} ${last}`,
      person_id: owner_id,
      organization_id: organization.id,
    })

    const service = getValues('service')
    if (service) {
      await createNotes({
        content: `Service of Interest: ${service}`,
        lead_id: lead.id,
      })
    }
    reset()
    setIsModalOpen(true)
    setIsLoad(false)
    afterSubmit?.()
  }

  return (
    <div className={styles.wrapper}>
      <Container>
        <Row>
          <Col lg={6} className={styles.form_image_container}>
            <img
              className={styles.form_image}
              src={require('../../../../../public/images/hp_form.jpg')}
            />
          </Col>
          <Col lg={6} className={styles.rightContainer}>
            <Container
              style={{
                paddingRight: 0,
                maxWidth: 1200,
              }}
            >
              <Row
                style={{
                  maxWidth: '100vw',
                }}
              >
                <Col xs={12}>
                  <div className={styles.form_content}>
                    <h3>Contact our team today</h3>
                    <p>
                      Want to talk about background checks or brainstorm about Due Diligence
                      investigation requirements?
                    </p>
                    <br />
                    <br />
                    <p>
                      We will put you directly in touch with a professional who will help guide you
                      to the answers you need.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>

            <form
              style={{}}
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmit(onSubmit)()
              }}
            >
              <Container>
                <Row>
                  <Col sm={6} className={styles.section}>
                    <Input
                      name="firstName"
                      register={register}
                      rest={{
                        disabled: isLoad,
                        style: {
                          border: errors.firstName ? '1px solid #ff000073' : '1px solid #E3D0C9',
                          background: errors.firstName ? '#f2dbdbf0' : '#ffff',
                          marginBottom: 0,
                        },
                        placeholder: 'First Name',
                      }}
                    />
                    <Input
                      register={register}
                      pattern={{
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'invalid email address',
                      }}
                      name="mail"
                      rest={{
                        disabled: isLoad,
                        style: {
                          border: errors.mail ? '1px solid #ff000073' : '1px solid #E3D0C9',
                          background: errors.mail ? '#f2dbdbf0' : '#ffff',
                          marginBottom: 0,
                        },
                        placeholder: 'Company Email',
                      }}
                    />
                    <Input
                      name="phone"
                      rest={{
                        disabled: isLoad,
                        placeholder: 'Business Phone (optional)',
                        style: {
                          border: '1px solid #E3D0C9',
                          marginBottom: 0,
                        },
                      }}
                    />
                  </Col>
                  <Col sm={6} className={styles.section}>
                    <Input
                      register={register}
                      name={'lastName'}
                      rest={{
                        disabled: isLoad,
                        style: {
                          border: errors.lastName ? '1px solid #ff000073' : '1px solid #E3D0C9',
                          background: errors.lastName ? '#f2dbdbf0' : '#ffff',
                          marginBottom: 0,
                        },
                        placeholder: 'Last name',
                      }}
                    />
                    <Input
                      register={register}
                      name={'org'}
                      rest={{
                        disabled: isLoad,
                        style: {
                          border: errors.org ? '1px solid #ff000073' : '1px solid #E3D0C9',
                          background: errors.org ? '#f2dbdbf0' : '#ffff',
                          marginBottom: 0,
                        },
                        placeholder: 'Organisation',
                      }}
                    />

                    <Input
                      register={register}
                      name={'service'}
                      defaultValue={''}
                      isDropdown
                      dropdownItems={[
                        'Integrity Due Diligence & Background Screening',
                        'Asset Tracing',
                        'Corporate Investigations',
                        'Strategic and Geopolitical Risk Advisory',
                        'Supply Chains and Sanctions Compliance',
                      ]}
                      onChange={(val: string) => {
                        setValue('service', val)
                      }}
                      rest={{
                        disabled: isLoad,
                        style: {
                          border: errors.service ? '1px solid #ff000073' : '1px solid #E3D0C9',
                          background: errors.service ? '#f2dbdbf0' : '#ffff',
                          marginBottom: 0,
                        },
                        placeholder: 'Service of Interest',
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Input
                      name="textArea"
                      rest={{
                        disabled: isLoad,
                        placeholder: 'How may we help you?',
                        style: {
                          border: '1px solid #E3D0C9',
                        },
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <br />
                    <Checkbox
                      id={'chx-1'}
                      isError={!!errors.checkbox1}
                      register={register}
                      name={'checkbox1'}
                      required={true}
                      label={
                        <p className={styles.checkbox_label}>
                          Sign up for Urus Advisory Newsletter.
                        </p>
                      }
                    />
                    <br />
                    <Checkbox
                      id={'chx-2'}
                      isError={!!errors.checkbox2}
                      register={register}
                      name={'checkbox2'}
                      label={
                        <p className={styles.checkbox_label}>
                          Submitting this form confirms your agreement to{' '}
                          <a href="/#/privacy" target="_blank">
                            Urus
                            <br /> Advisory Privacy Policy
                          </a>{' '}
                          and{' '}
                          <a href="/#/cookies" target="_blank">
                            Cookies Policy
                          </a>
                          .
                        </p>
                      }
                    />
                    <button>
                      {'SEND MESSAGE'}
                      {isLoad && (
                        <TailSpin
                          // visible={false}
                          height="25"
                          width="25"
                          color="#fff"
                          ariaLabel="line-wave-loading"
                          wrapperClass={styles.loader}
                          wrapperStyle={{}}
                        />
                      )}
                    </button>
                  </Col>
                </Row>
              </Container>
            </form>
          </Col>
        </Row>
      </Container>
      <SuccessModal isOpen={isModalOpen} handleModal={(val: boolean) => setIsModalOpen(val)} />
    </div>
  )
}

export default Form
