const meet_our_team = [
  {
    name: 'Alexei Panin',
    title: 'Managing Director',
    location: 'Riga, Latvia',
    imageName: require('../../public/images/Alexei_3_small.jpg'),
    specialities: [
      'Eastern Europe',
      'Western Asia',
      'Central Asia',
      'Political and country risk',
      'Ethics, compliance, and governance',
      'Supply chains',
    ],
    description: `Alexei is a founding partner of Urus Advisory. He has over 15 years of experience managing end-to-end complex investigations and advisory projects across Eastern Europe and parts of Asia. Some of Alexei’s most remarkable cases include leading an international team of investigators in a banking fraud affair in Central Asia, advising major banking, industrial, and consulting groups in Eastern Europe, and managing a complex due diligence of an energy conglomerate ahead of privatisation. 
    
    Alexei has studied at leading universities in Austria, Latvia, Russia, and the UK. He is fluent in Russian, English, and German and a regular ice hockey player.`,
  },
  {
    name: 'Alexander Teddy',
    title: 'Managing Director',
    imageName: require('../../public/images/Alex.jpg'),
    location: 'London, United Kingdom',
    specialities: [
      'All Europe',
      'Western, Central and Eastern Asia',
      'Investigation Services',
      'Ethics and compliance consulting',
      'Risk assessments and strategic advisory',
      'Finance and banking',
    ],
    description: `Alexander has a wealth of experience in the field of risk advisory, finance strategy, and reporting, having worked with clients from both the FTSE-100 and Fortune 500 lists, as well as top law firms and litigators. Throughout his career, which has spanned over 15 years, he has taken on projects across the Eurasia: the UK and Europe, Middle East and Africa, as well as the CIS region.
      
      He holds degrees from the University of Oxford and Bristol, and is proficient in 5 languages, including English, French, Polish, Russian, and Spanish, with a basic knowledge of Mandarin.
      `,
  },
  {
    name: 'Hipolit Arthur Gachowski',
    title: 'Head of Finance & Business Support',
    location: 'London, UK',
    imageName: require('../../public/images/AG_bw.jpg'),
    specialities: [
      'Finance',
      'Engineering',
      'Technology',
      'Media and production',
      'UK, Central & Eastern Europe',
    ],
    description: `Hipolit is responsible for finance and business support functions at Urus Advisory. He has a solid track record of over 25 years in business development and implementation of technology solutions for various organisations in the UK and overseas. 

    He speaks Polish, English, and Russian. He is a keen sailor passionate about politics and history.`,
  },
  {
    name: 'Juergen Stichenwirth',
    title: 'Head of Sales & Marketing',
    location: 'Vienna, Austria',
    imageName: require('../../public/images/JS-photo.jpeg'),
    specialities: [
      'Sales & Marketing',
      'FinTech & Banking',
      'Retail & Healthcare',
      'Security',
      'Public Sector',
    ],
    description: `Juergen has been active in international sales for more than 30 years. He started his career in global blue chips such as Citi, Compaq / Hewlett Packard and Western Union, where his international background led him to head up sales teams across more than 50 countries, closing deals on all continents. In founding his own sales advisory, he accumulated extensive sales and marketing experience in sectors as diverse as FinTech, Banking, Security, Healthcare, Retail and the public sector. 

    Juergen, an Austrian national, was raised in the United States, and speaks four languages.
    `,
  },
  {
    name: 'Artem Sentsov',
    title: 'Head of Technology, ClearPic CTO',
    location: 'Riga, Latvia',
    specialities: [
      'System Design & Development',
      'Machine Learning & AI',
      'Business Intelligence & Research',
      'Compliance & Risk Management',
      'Large-Scale Data Systems',
    ],
    description: `Artem oversees technology and innovation at Urus Advisory, specializing in advanced compliance tools and risk management solutions. With experience at a Big 4 firm and a master’s degree from Lomonosov Moscow State University, he has a strong foundation in system design, machine learning, and business intelligence.

    He speaks Russian, English, and German. In his free time, he enjoys exploring Chinese teas and philosophy, as well as delving into mathematics.
    `,
  },
  {
    name: 'Thomas Kruessmann',
    title: 'Legal Counsel, Advisor',
    location: 'Tashkent, Uzbekistan',
    // TODO:
    imageName: require('../../public/images/TK-photo.jpg'),
    specialities: [
      'Law, European legal systems',
      'Forensic Evidence',
      'Specialist Situations',
      'Central & Eastern Europe',
      'East Asia',
    ],
    description: `Prof. Thomas Kruessmann is a German-qualified lawyer with one of Vienna’s leading law firms. He is founding director of a Eurasian centre at the University of Graz, a Visiting Professor at various universities across Eurasia and has worked in China and Hong Kong.

    He speaks German, English, Russian and other languages. Thomas is a keen writer and speaker.
    `,
  },
  {
    name: 'Trevor Marshall',
    title: 'Head of Production & Editing',
    location: 'Prague, Czech Republic',
    imageName: require('../../public/images/TM-photo.jpeg'),
    specialities: [
      'Oversees Urus style guide',
      'Worked in criminal justice, social work and education',
      'Freelance writer and editor',
      'Lived internationally for 20 years',
      'Freelance photographer',
    ],
    description: `Trevor has worked as a probation officer, social worker and teacher, as well as freelance photogapher, author, content writer and editor. Being an external examiner for the International Bauccalaureate Organisation has given him a keen eye for detail.

    Trevor is a keen musician and DIY enthusiast, and loves trying to fix things without having too many bits left over when he finishes. 
    `,
  },
  {
    name: 'Alexei S',
    title: 'Head of Background Screening',
    location: '',
    specialities: [
      'Eastern Europe',
      'Central Asia',
      'Western Asia',
      'Aviation industry',
      'Background screening',
      'Integrity due diligence',
    ],
    description: `Alexei is the Head of Background Screening at Urus Advisory. He previously worked as a senior consultant and analyst at corporate intelligence and risk advisory companies for over 10 years with the main focus on Russia and FSU countries.
      
      Alexei studied political science and trained in compliance and investment migration. He is proficient in English and Russian, with a basic knowledge of Turkish.
      `,
  },
  {
    name: 'Javier E',
    title: 'Head of Strategic and Geopolitical Risk Advisory',
    location: 'Madrid, Spain',
    specialities: [
      'Southern Europe',
      'Central Asia',
      'Energy',
      'Financial services',
      'Political and country risk',
      'Sanctions due diligence',
    ],
    description: `Javier is the Head of Strategic and Geopolitical Risk Advisory. He has worked on hundreds of complex investigations, including country and political risk, special situations, sanctions due diligence and compliance across most of Eurasia.
    
    Javier is fluent in Spanish, Italian, English and Russian. He holds a MA in Geopolitics and Grand Strategy from the University of Sussex (UK). Javier is a born traveler and chess enthusiast.`,
  },
  {
    name: 'Denis S',
    title: 'Head of Corporate Investigations',
    location: '',
    specialities: [
      'Eastern Europe',
      'Central Asia',
      'Banks and other financial institutions',
      'Oil and gas',
      'Corporate investigations',
      'Asset tracing',
    ],
    description: `Denis is the Head of Corporate Investigations at Urus Advisory, with a rich background in the field spanning 12 years. His experience encompasses over 500 projects across Eurasia, the US, and Australia, extending to a wide range of sectors, from finance and investment banking to commodities trading. With substantial knowledge of over 20 jurisdictions, his core expertise lies in asset tracing and affiliations mapping.

    Denis holds diplomas in international relations and compliance, trained in investigative interviews and is proficient in both English and Russian. He is passionate about exploring art and architecture, as well as urban development as a hobby.
    `,
  },
  {
    name: 'Kseniia S',
    title: 'Head of Pharma & FMCG',
    location: '',
    specialities: [
      'Eastern Europe',
      'Central Asia',
      'Pharmaceutical industry',
      'Healthcare industry',
      'Integrity due diligence',
      'Corporate investigations',
    ],
    description: `Kseniia has over 10 years of experience in business and investigative journalism. She worked as an editor in a leading specialist pharma industry publication and has a wide network of contacts across the pharma market in the FSU and beyond.

    Kseniia has developed advanced interviewing skills during more than five years of risk advisory work in economic security, business intelligence, political risk and compliance. She is a book lover, interested feminism, gender issues and postcolonial studies.
    `,
  },
  {
    name: 'Sergei G',
    title: 'Head of Supply Chains and Sanctions Compliance',
    location: 'Istanbul, Türkiye',
    specialities: [
      'Central Asia',
      'Western Asia',
      'Automotive industry',
      'Software industry',
      'Sanctions due diligence',
      'Fraud investigations',
    ],
    description: `Sergei is the Head of Supply Chains and Sanctions Compliance at Urus Advisory, with a track record of over 500 successful projects in his career. His expertise centers on identifying potential risks across various jurisdictions in Central and Western Asia, as well as Eastern Europe. Before his tenure at Urus, Sergei was involved in anti-counterfeit and trademark infringement investigations for some of the world's leading electronics manufacturers.

    Fluent in Russian and English, Sergei is a longtime martial arts practitioner with a keen interest in the latest high-tech developments.
    `,
  },
  // xenia
  // {
  //   name: 'Ana N',
  //   title: 'Senior associate',
  //   location: '',
  //   specialities: [
  //     'Eastern Europe',
  //     'Central Asia',
  //     'Medicine and pharmaceuticals',
  //     'IT industry',
  //     'Corporate investigations',
  //     'Cartelisation'
  //   ],
  //   description:`Ana has more than 10 years of experience in business and investigative journalism, including major international publications. She is proficient in a wide range of OSINT and data analysis tools as well as interviewing, from expert discussions to investigative enquiries.

  //   She is fluent in English and an accomplished chess player.
  //   `,
  // },
  {
    name: 'Anthony L',
    title: 'Senior associate',
    location: 'Istanbul, Türkiye',
    specialities: [
      'Eastern Europe',
      'Central Asia',
      'Real estate industry',
      'Integrity due diligence',
      'Political and country risk',
    ],
    description: `Anthony is a seasoned practitioner who has worked in corporate investigations and compliance since 2013. In 2016, he joined Urus Advisory as one of the first employees. During this time he has completed more than 500 projects across Eastern Europe and Central Asia and worked with clients from different industries: real estate, construction, financial services, retail, energy and others.

    Anthony has studied history and trained as a compliance manager. He is fluent in Russian, English and an experienced mountain skier.    
    `,
  },
  {
    name: 'Will C',
    title: 'Senior associate',
    location: 'Antalya, Türkiye',
    specialities: [
      'Western Europe',
      'Southern Europe',
      'Sports industry',
      'Real estate',
      'Political and country risk',
    ],
    description: `Will is a Senior Associate at Urus Advisory. He has worked on dozens of due diligence investigations and political risk projects across Western and Southern Europe, including in Türkiye and the Balkan countries.

    Will is a native English speaker and is fluent in French and Russian with a working knowledge of Spanish and Turkish. He graduated with First Class Honours in European Studies with French and Russian from the University of Bath (UK). Will enjoys playing and watching sport as well as studying Turkish language and history.`,
  },
  {
    name: 'Sergey K',
    title: 'Senior associate',
    location: '',
    specialities: [
      'Eastern Europe',
      'Central Asia',
      'Integrity due diligence',
      'Background screening',
      'Corporate Investigations',
    ],
    description: `Sergey is an experienced investigator with 15 years of experience in corporate investigations, business intelligence and background screening. He has a track record of over 500 successful projects in his career, across various jurisdictions and industries. In his earlier work, Sergey was involved in anti-counterfeit and trademark infringement investigations for some of the world's leading FMCG manufacturers.

    Sergey studied world economy and is fluent in Russian and English, with a basic knowledge of Spanish.`,
  },
  {
    name: 'Olga P',
    title: 'Senior Associate',
    location: 'London, United Kingdom',
    specialities: ['Eastern Europe', 'East Asia', 'Central Asia', 'Political and country risk'],
    description: `Olga holds a doctorate from a leading UK university and is an accomplished researcher specialising in international relations. Her focus is on the political dynamics and risk landscapes of East and Central Asia - and she speaks fluently several East Asian languages. Building on a foundation of rigorous academic training and research, she has developed a reputation for conducting top macro-level analysis. Her work addresses systemic trends and structural forces, emphasising the indispensable role of contextual understanding in complex geopolitical environments.

    As hobbies, Olga enjoys film, literature and sports.`,
  },
  {
    name: 'Andrew D',
    title: 'Associate',
    location: 'Astana, Kazakhstan',
    specialities: [
      'Eastern Europe',
      'Central Asia',
      'Mass media',
      'Entertainment industry',
      'Background screening',
      'Cyber and digital',
    ],
    description: `Andrew has worked at Urus Advisory since 2019. He completed more than 350 background screening projects across Eastern Europe, Central and Western Asia and has vast experience in OSINT investigations. 

    Andrew studied politics, economics, and public relations at a top-5 university in Eastern Europe for economics and finance. He is fluent in Russian and English with a basic knowledge of Kazakh. Andrew professionally plays guitar and is an accomplished contestant of intellectual games.
    `,
  },
  {
    name: 'Lana A',
    title: 'Associate',
    location: '',
    specialities: [
      'Central Asia',
      'Eastern Europe',
      'Entertainment industry',
      'Education industry',
      'Ethics, compliance, and governance',
      'ESG',
    ],
    description: `Lana has been working at Urus Advisory since 2019. She began her career as a research associate in the field of political science and sociology at a top Eastern European university. Lana has worked on more than 500 investigative projects across Eastern Europe and Central Asia.

    Lana dedicates her free time to participating in eco-sociological volunteering programs in the Arctic region. Keen traveler, she spent several years between the Balkan states, Central Asia, and the Caucasus.`,
  },
  {
    name: 'Max K',
    title: 'Associate',
    location: 'Astana, Kazakhstan',
    specialities: [
      'Eastern Europe',
      'Central Asia',
      'Telecommunications industry',
      'Integrity due diligence',
      'Asset tracing',
    ],
    description: `Max has over 7 years of experience in due diligence, background screening, and asset tracing, being part of more than 500 investigative projects across Eastern Europe and Central Asia.

    Max studied political science and economics in a top-5 university in Eastern Europe. He is fluent in Russian and English, and has passion for football, history and intellectual games.
    `,
  },
  {
    name: 'Sergo R',
    title: 'Associate',
    location: 'Tbilisi, Georgia',
    specialities: [
      'Western Asia',
      'Central Asia',
      'Transportation industry',
      'Fishing industry',
      'Background screening',
      'Corporate investigations',
    ],
    description: `Sergo has over 15 years of experience in compliance, risk assessment and due diligence. Starting his career in a government regulatory agency, he continued his work in one of the top 4 international transportation companies, rising from legal counsel to department head. Later, Sergo led compliance departments in companies specialising in food production. 

    Sergo studied international law and trained in AML/CFT. He is proficient in Russian and English with a basic knowledge of French. Sergo enjoys traveling and capturing moments of life through photography.`,
  },
  {
    name: 'John A',
    title: 'Junior associate',
    location: 'Oxford, UK',
    specialities: [
      'Eastern Europe',
      'Southern Europe',
      'Southeastern Asia',
      'Greater China',
      'Political and Country Risk',
      'Supply chains',
    ],
    description: `John is a Junior Associate at Urus Advisory. He studied politics and philosophy at Lancaster University. Before joining Urus, he had worked at a law firm, a commodities company and a news organisation.

    John has lived and worked in Hong Kong and the UK, and is fluent in English and Mandarin Chinese. He is currently learning Bulgarian and Russian as well. He enjoys reading, cycling and board games in his spare time.
    `,
  },
]

export default meet_our_team
