import React, { useEffect } from 'react'

import HeaderSpacing from '../../shared/components/HeaderSpace/HeaderSpace'
import Header from '../../shared/components/Header_old/Header'
import TextBubbleSubPages from '../../shared/components/TextBubble/TextBubbleSubPages'
import { pushToGTM } from '../../shared/utis/gtm'
import Footer from '../Home/components/Footer/Footer'
import Form from '../Home/components/Form/Form'
import Bubble from '../Service/components/Bubble/Bubble'
import SpecialBlog from '../Service/components/SpecialBlog/SpecialBlogNoPhoto'

const StrategicPoliticalRiskAdvisory = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // @ts-ignore
      behavior: 'instant',
    })
  }, [])

  return (
    <div>
      <HeaderSpacing />
      <Header
        headerText={`Strategic and Geopolitical Risk Advisory`}
        subHeaderText="We dissect, analyse and transform data and information from reliable sources into actionable intelligence"
        headerStyles={{}}
        onRequestClick={() => {
          pushToGTM({
            tagName: '“Request a service” button (Strategic Risk Advisory)',
            event: 'request_service',
          })
        }}
        headerSubTitleStyle={{}}
        headerWrapperStyles={{
          background: '#191736',
        }}
        buttonText={'Request a service '}
        buttonStyles={{
          fontWeight: 700,
        }}
      />
      <TextBubbleSubPages
        leftSection={
          <>
            <h3
              style={{
                textAlign: 'left',
              }}
            >
              Strategic and Geopolitical Risk Advisory
            </h3>
            <p>
              Many of the turnkey events in recent history have been the consequence of political
              decisions or events. Investors are increasingly aware of the need to conduct thorough
              geopolitical risk analysis to evaluate how events such as a change in the political
              leadership, tensions among the elite, social unrest, escalation and spill-over of
              armed conflicts can potentially impact their interests.
            </p>
            <p>
              Our tailored approach allows us to identify potential disruptive factors in real time
              and advise our clients on how to better mitigate their effect.
            </p>
          </>
        }
        rightTitle={'The services available for our clients are:'}
        direction={'column'}
        bubbleItems={[
          'Geopolitical risk analysis',
          'Geopolitical and country risk monitoring',
          'Sanctions risks analysis ',
          'Scenario mapping',
          'Power mapping',
          'Regulatory risks analysis',
          'Market entry and market exit risk assessment',
        ]}
      />
      <Bubble
        direction={'column'}
        styleType="points"
        title="Why choose Urus Advisory as your Strategic and Geopolitical Risk Advisory partner?"
        bubbleItems={[
          {
            title: 'Experience',
            description:
              'Interdisciplinary approach and background in geopolitics, corporate intelligence, political science, economics, and social studies.',
          },
          {
            title: 'Access',
            description: 'Wide network of experts on the ground in key jurisdictions.',
          },
          {
            title: 'Approach',
            description: 'Diligence, quality and confidentiality.',
          },
        ]}
      />

      <SpecialBlog
        quote={
          'Many of our Clients are decision-makers, and thus, our task is to ensure that those decisions are as informed as possible in the current context of uncertainty and volatility. They do not need long descriptive reports but sound analysis of key indicators behind risk factors. In other words, they need actionable intelligence.'
        }
        author={'Javier E - Head of Strategic and Geopolitical Risk Advisory'}
      />
      <Form
        serviceOfInterest="Strategic and Geopolitical Risk Advisory"
        afterSubmit={() => {
          pushToGTM({
            event: 'contact_form_submit',
            tagName: 'Contact form submission “Contact our team today” (Strategic Risk Advisory)',
          })
        }}
      />
      <Footer isMinimized={false} />
    </div>
  )
}

export default StrategicPoliticalRiskAdvisory
