import React, { useEffect } from 'react'

import Comments from '../../shared/components/Comments/Comments'
import FourColumns from '../../shared/components/FourColumns/FourColumns'
import Header from '../../shared/components/Header/Header'
import HeaderSpacing from '../../shared/components/HeaderSpace/HeaderSpace'
import PreviewImage from '../../shared/components/PreviewImage/PreviewImage'
import TextBubble from '../../shared/components/TextBubble/TextBubble'
import TextBubble2 from '../../shared/components/TextBubble/TextBubble2'
import TextBubble3 from '../../shared/components/TextBubble/TextBubble3'
import TextBubble1 from '../../shared/components/TextBubble/TextBubbleLeft'
import { pushToGTM } from '../../shared/utis/gtm'
import useScrollToPage from '../../utils/useScrollToPageRef'
import About from '../About/About'
import CSForm from './components/CS-Form/Form'
import Footer from './components/Footer/Footer'
import Form from './components/Form/Form'
import HPForm from './components/HP-Form/Form'
import HeaderText from './components/HeaderText/HeaderText'
import Map from './components/Map/Map'
import Strategic from './components/Strategic/Strategic'
import WorkWithUs from './components/WorkWithUs/WorkWithUs'

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // @ts-ignore
      behavior: 'instant',
    })
  }, [])
  return (
    <>
      <Header
        headerText="Premier risk intelligence partner in Eurasia"
        subHeaderText="Empowering your business decisions through complete analysis of integrity, corruption and fraud risks across non-transparent jurisdictions."
      />
      <Comments />
      <TextBubble1
        // isHrEnable={true}
        leftSection={
          <>
            <h3 style={{}}>Together</h3>
            <p>
              The business world has become more connected but also more unknown.{' '}
              <b>Reliable local partners are needed everywhere</b> - to mitigate risks, assess
              opportunities, or conduct investigations into potential legal or regulatory issues.
            </p>
            <p>
              The need for risk intelligence and due diligence has become crucial in today's
              business landscape.
            </p>
            <p>
              <b>
                For almost a decade, our growing expertise across much of Eurasia has earned the
                trust of more than 150 clients, supporting their growth over time.
              </b>
            </p>
          </>
        }
        rightTitle={'Our clients include:'}
        direction={'row'}
        bubbleItems={[
          ['Investment Funds', 'Financial Institutions', 'Corporations'],
          ['Private Investors', 'Risk Investigators'],
          ['Technology Firms', 'Litigation Funds and Practitioners'],
        ]}
        bubbleItemsXS={[
          'Investment Funds',
          'Financial Institutions',
          'Corporations',
          'Private Investors',
          'Risk Investigators',
          'Technology Firms',
          'Litigation Funds and Practitioners',
        ]}
      />
      <TextBubble2
        leftSection={
          <>
            <h3
              style={{
                color: '#fff',
              }}
            >
              We highlight risks and help safeguard opportunities
            </h3>
            <br />
            <p>
              The Urus Advisory team has grown from its early days in 2016, covering the FSU and
              Central Asia, adding ever wider geographical expertise and sectoral know-how.
            </p>
            <p>
              With <b>over 20 in-house expert analysts and a vast network of local partners,</b> our
              firm is known for its insightful risk intelligence services.
            </p>
            <p>
              Our team is equipped to provide your business with comprehensive analysis and advisory
              support tailored to address a variety of situations.
            </p>
          </>
        }
        rightTitle={'Our MAIN services:'}
        direction={'column'}
        bubbleItems={[
          {
            title: 'Integrity Due Diligence & Background Screening',
            link: '/services',
          },
          {
            title: 'Corporate Investigations',
            link: '/corporate-investigations',
          },
          {
            title: 'Asset tracing, Litigation & Dispute Support',
            link: '/asset-tracing',
          },
          {
            title: 'Strategic & Political Risk Advisory',
            link: '/strategic-political-risk',
          },
          {
            title: 'Supply Chains and Sanctions Compliance',
            link: '/supply-chains-sanctions',
          },
        ]}
      />
      <TextBubble3
        leftSection={
          <>
            <h3>... by contextualising the micro and placing it into the macro</h3>
            <br />
            <p>
              Evolving regulatory standards require modern approaches and the use of new
              technologies. When you work with us, you get a complete picture, powered by the best
              tools, many developed in-house.
            </p>
            <p>
              By tapping into our <b>human talent and AI resources,</b> you can connect previously
              unlinked information from validated sources: legal documents, regulatory filings,
              public records, interviews, reference checks, and other relevant data points.
            </p>
            <br />
            <br />
            <br />
          </>
        }
        rightTitle={'WE ARE ready to help you'}
        direction={'column'}
        styleType={'points'}
        bubbleItems={[
          'Ensure that a potential business partner, employee or investment does not pose a risk to your reputation or financial stability',
          `Perform large-scale enhanced due diligence work ahead\n of new market entry`,
          'Protect your business interests during litigation, arbitration\n and disputes',
        ]}
      />
      <FourColumns />
      <WorkWithUs />
      <PreviewImage source={require('../../../public/images/home_preview.jpg')} position={'55%'} />
      <HeaderText />
      <Map />
      <Strategic />
      <HPForm
        afterSubmit={() => {
          pushToGTM({
            event: 'contact_form_submit',
            tagName: 'Contact form submission “Contact our team today” (homepage)',
          })
        }}
      />
      <Footer />
    </>
  )
}
export default Home
