import React from 'react'
import { Col, Container, Row } from 'react-grid-system'

import styles from './styles.module.scss'

const HeaderText = () => {
  return (
    <div className={styles.block}>
      <Container>
        <Row>
          <Col md={4}>
            <h3>Global to Local: High-resolution research</h3>
          </Col>
          <Col md={8}>
            <div className={styles.content}>
              <p>
                Urus Advisory has spent years carefully building up know-how and networks in
                multiple countries, in the major industries and across all levels of businesses and
                local economies. Our methodology is never to cut corners, but always seek first-hand
                information, locally sourced, and exclusive for our clients.
              </p>
              <p>
                Practice shows that even the most international decision-making is routed in local,
                close knowledge across many areas. Clients benefit significantly from using our
                ever-ready and fine-grain read on regional risks to apply to their big-picture
                decision-making.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default HeaderText
