export const DROPDOWN_DATA = [
  {
    label: 'Integrity Due Diligence \n& Background Screening',
    value: '/services',
  },
  {
    label: 'Asset Tracing',
    value: '/asset-tracing',
  },
  {
    label: 'Corporate Investigations',
    value: '/corporate-investigations',
  },
  {
    label: 'Strategic and Geopolitical Risk Advisory',
    value: '/strategic-political-risk',
  },
  {
    label: 'Supply Chains and Sanctions Compliance',
    value: '/supply-chains-sanctions',
  },
]
