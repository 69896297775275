import React, { ReactElement, RefObject, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation, useOutlet } from 'react-router'
import ScrollToTop from 'react-scroll-to-top'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import useScrollToPage from '../../../utils/useScrollToPageRef'
import Arrow from '../../icons/Arrow'
import NavBar from '../Navbar/Navbar'

const Layout = ({
  routes,
}: {
  routes: {
    path: string
    element: ReactElement
    nodeRef: RefObject<HTMLDivElement>
  }[]
}) => {
  const location = useLocation()
  const { nodeRef } = routes.find((route) => route.path === location.pathname) ?? {}
  const { isBottomOfThePage, goToPage } = useScrollToPage()
  const currentOutlet = useOutlet()

  useEffect(() => {
    if (location.pathname) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname })
    }
  }, [location.pathname])

  return (
    <>
      <SwitchTransition>
        <CSSTransition key={location.pathname} timeout={500} classNames="page" unmountOnExit>
          <div ref={nodeRef} className="page" style={{}}>
            <NavBar />
            {currentOutlet}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <ScrollToTop
        smooth
        color="white"
        top={500}
        style={{
          background: '#EC5922',
          borderRadius: '50%',
          height: 50,
          width: 50,
          transition: 'all 0.3s',
          bottom: isBottomOfThePage ? 140 : 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
      {isBottomOfThePage && (
        <div
          onClick={() => {
            goToPage()
          }}
          style={{
            background: '#EC5922',
            borderRadius: '50%',
            height: 50,
            width: 50,
            transition: 'all 0.3s',
            bottom: 40,
            position: 'fixed',
            right: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
        >
          <Arrow
            customStyle={{
              transform: 'rotate(-180deg)',
              boxShadow: '0 9px 25px 0 rgba(132, 128, 177, 0.28)',
            }}
          />
        </div>
      )}
    </>
  )
}

export default Layout
