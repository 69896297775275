import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { Parallax } from 'react-parallax'
import { Link, useNavigate } from 'react-router-dom'

import styles from './styles.module.scss'

const Header = ({
  headerSubTitleStyle = {},
  headerWrapperStyles = {},
  buttonStyles = {},
  headerStyles = {},
  headerText = '',
  subHeaderText = '',
  buttonText = 'talk with an expert',
  onRequestClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {},
}) => {
  const navigate = useNavigate()
  return (
    <Parallax
      blur={{ min: -10, max: 10 }}
      bgClassName={styles.parrallax}
      strength={200}
      bgImage={require('../../../../public/images/Home.jpg')}
    >
      <div className={styles.header} style={{ ...headerWrapperStyles }}>
        <Container fluid className={styles.container}>
          <Row>
            <Col sm={9}>
              <div className={styles.header_banner}>
                <h1 className={styles.title} style={{ ...headerStyles }}>
                  {headerText}
                </h1>
                <p className={styles.subtitle} style={{ ...headerSubTitleStyle }}>
                  {subHeaderText}
                </p>
              </div>
            </Col>
            <Col sm={9}>
              <div className={styles.btn_wrapper}>
                <Link to="/contact">
                  <button
                    onClick={(e) => {
                      e.preventDefault()
                      onRequestClick?.(e)
                      navigate('/contact')
                    }}
                    style={{ ...buttonStyles }}
                    className={styles.header_banner_btn}
                  >
                    {buttonText}
                  </button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Parallax>
  )
}

export default Header
