import { geoPath, geoCentroid, geoNaturalEarth1, GeoPermissibleObjects } from 'd3-geo'
// @ts-ignore
import { geoMiller } from 'd3-geo-projection'
import { debounce } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps'

import { subregions, totalProjects } from '../../../../data/map'
import MapGeography from './MapGeography'
import ZoomableMobile from './ZoomableMobile'
import geo from './geo'
import styles from './styles.module.scss'

export type SubregionsType = keyof typeof subregions

const Map = () => {
  const width = window.innerWidth

  const data = [
    { country: 'cn', value: 1389618778 }, // china
    { country: 'in', value: 1311559204 }, // india
    { country: 'us', value: 331883986 }, // united states
    { country: 'id', value: 264935824 }, // indonesia
    { country: 'pk', value: 210797836 }, // pakistan
    { country: 'br', value: 210301591 }, // brazil
    { country: 'ng', value: 208679114 }, // nigeria
    { country: 'bd', value: 161062905 }, // bangladesh
    { country: 'ru', value: 141944641 }, // russia
    { country: 'mx', value: 127318112 }, // mexico
  ]

  useEffect(() => {}, [])

  const [populationData, setPopulationData] = useState(false)
  const [optimizationDisabled, setOptimizationDisabled] = useState(false)

  const switchDataset = () => {
    setOptimizationDisabled(true)
    setPopulationData((prev) => !prev)
  }
  const width1 = 800
  const height = 600

  const projection = geoMiller()
    .rotate([-10, 0])

    .translate([width1 / 2, height / 2])
    .scale(120)

  const getCoords = (props: any, provinceCenter: number[]): [number, number] => {
    if (props.subregion == 'Central Asia') {
      return [provinceCenter[0], provinceCenter[1] + 5]
    }
    if (props.subregion == 'Western Europe') {
      return [provinceCenter[0] - 5, provinceCenter[1] + 2]
    }
    return [provinceCenter[0], provinceCenter[1]]
  }

  const roundBorders = (geo: GeoPermissibleObjects) => {
    const path = geoPath().projection(geoNaturalEarth1())
    const roundedPath = (feature: GeoPermissibleObjects) => {
      return path(feature)?.replace(/(\d+)\.(\d+)/g, (match, p1, p2) => {
        const rounded = Math.round(Number(`${p1}.${p2}`))
        return `${rounded}`
      })
    }
    return { ...geo }
  }
  const [hoveredSubregion, setHoveredSubregion] = useState<SubregionsType | ''>('')
  const handleMouseEnter = (subregion: SubregionsType) => {
    setHoveredSubregion(subregion)
  }
  const debouncedHandleMouseEnter = debounce(handleMouseEnter, 200)

  const handleMouseLeave = () => {
    setHoveredSubregion('')
  }

  return (
    <div
      style={{
        background: '#C3E8FF',
        padding: 0,
      }}
      className={styles.test}
    >
      <Container
        className={styles.map}
        style={{
          maxWidth: 1156.7,
        }}
      >
        <div>
          <h3 className={styles.projects}>Total projects: {totalProjects}</h3>
        </div>
        <Row>
          <Col>
            <ComposableMap
              projection={projection}
              projectionConfig={{
                scale: 100,
              }}
            >
              <ZoomableMobile>
                <Geographies
                  geography={geo}
                  style={{
                    background: 'red',
                  }}
                >
                  {({ geographies }) => (
                    <>
                      {geographies.map((geo, i) => {
                        if (geo.properties.shouldShowForCurrentRegion) {
                          const provinceCenter = geoCentroid(geo)
                          const cords = getCoords(geo.properties, provinceCenter)
                          if (subregions[geo.properties.subregion as SubregionsType]) {
                            ;(subregions[geo.properties.subregion as SubregionsType]
                              .cords as unknown as number[]) = cords
                          }
                        }
                        const subReg = geo.properties.subregion as keyof typeof subregions

                        const color = subReg ? subregions[subReg].color : '#14346F'

                        return (
                          <MapGeography
                            color={color}
                            key={i}
                            debouncedHandleMouseEnter={debouncedHandleMouseEnter}
                            geo={geo}
                            hoveredSubregion={hoveredSubregion}
                          />
                        )
                      })}
                    </>
                  )}
                </Geographies>
              </ZoomableMobile>
            </ComposableMap>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Map
