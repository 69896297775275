import React from 'react'
import ReactDOM from 'react-dom/client'

import '../public/fonts/Inter-Medium.ttf'
import '../public/fonts/Inter-Regular.ttf'
import '../public/fonts/Inter-Regular.ttf'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(<App />)
