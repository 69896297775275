import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { Parallax } from 'react-parallax'

import useNavigateWithGTM from '../../hooks/useNavigateWithGTM'
import styles from './styles.module.scss'

const Header = ({
  headerSubTitleStyle = {},
  headerWrapperStyles = {},
  buttonStyles = {},
  headerStyles = {},
  headerText = '',
  subHeaderText = '',
  buttonText = 'talk with an expert',
}) => {
  const { navigateWithGTM } = useNavigateWithGTM()

  return (
    <Parallax
      blur={{ min: -10, max: 10 }}
      bgClassName={styles.parrallax}
      strength={200}
      bgImage={require('../../../../public/images/Home.jpg')}
      style={{
        height: '100vh',
      }}
    >
      <div className={styles.header} style={{ ...headerWrapperStyles }}>
        <Container className={styles.container}>
          <Row>
            <Col sm={11}>
              <div className={styles.header_banner}>
                <h1 className={styles.title} style={{ ...headerStyles }}>
                  {headerText}
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.bottom_header}>
        <Container style={{}}>
          <Row>
            <Col md={6}>
              <p className={styles.subtitle} style={{ ...headerSubTitleStyle }}>
                {subHeaderText}
              </p>
            </Col>
            <Col md={3}></Col>
            <Col md={3}>
              <div className={styles.btn_wrapper}>
                {/* <Link to="/contact"> */}
                <button
                  style={{ ...buttonStyles }}
                  className={styles.header_banner_btn}
                  onClick={() =>
                    navigateWithGTM('/contact', {
                      event: 'talk_with_an_expert',
                      tagName: 'Button “Talk with an expert”',
                    })
                  }
                >
                  {buttonText}
                </button>
                {/* </Link> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Parallax>
  )
}

export default Header
