import React, { useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { DROPDOWN_DATA } from '../../constants'
import CustomDropdown from '../Dropdown/Dropdown'
import styles from './styles.module.scss'

const NavBar = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)

  const openMenuToggle = () => {
    setIsOpen((prev) => !prev)
  }
  return (
    <div className={styles.navbar}>
      <Container>
        <Row align="center">
          <Col sm={12} md={3}>
            <div className={styles.image}>
              <Link to={'/'}>
                <img
                  className={styles.logo}
                  src={require('../../../../public/images/logo_white.svg')}
                  alt=""
                />
              </Link>
              <img
                className={`${styles.burger} ${isOpen && styles.active}`}
                onClick={openMenuToggle}
                width={18}
                src={require('../../../../public/images/menu.svg')}
              />
            </div>
          </Col>
          <Col sm={12} md={9} className={styles.links_container}>
            <div className={`${styles.links} ${isOpen && styles.open}`}>
              <CustomDropdown
                activeStyles={{
                  color: '#EC5922',
                }}
                shouldBeOpenedByDefault={
                  DROPDOWN_DATA.findIndex((item) => location.pathname.indexOf(item.value) == 0) !==
                  -1
                }
                forcedActiveItem={DROPDOWN_DATA.find(
                  (item) => location.pathname.indexOf(item.value) == 0
                )}
                options={DROPDOWN_DATA}
                defaultValue={'Services'}
                click={(val) => {
                  navigate(val)
                }}
              />
              <Link
                to="/about"
                className={`${styles.links} ${location.pathname.indexOf('/about') == 0 && styles.active}`}
              >
                About Us
              </Link>
              <Link
                to="/contact"
                className={`${styles.links} ${location.pathname.indexOf('/contact') == 0 && styles.active}`}
              >
                Contact
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NavBar
