import React from 'react'
import { Geography } from 'react-simple-maps'

import { subregions } from '../../../../data/map'
import { SubregionsType } from './Map'
import CustomMarker from './Marker'

const MapGeography = ({
  debouncedHandleMouseEnter,
  geo,
  hoveredSubregion,
  color,
}: {
  debouncedHandleMouseEnter: (val: any) => any
  geo: any
  hoveredSubregion: string
  color: string
}) => {
  return (
    <>
      <Geography
        key={geo?.rsmKey}
        strokeWidth={0.3}
        onMouseEnter={(_) => {
          if (subregions[geo.properties.subregion as SubregionsType].projects) {
            debouncedHandleMouseEnter(geo.properties.subregion)
          }
        }}
        geography={(() => {
          return geo
        })()}
        style={{
          default: {
            transition: 'all 0.5s',
            fill: color,
            outline: 'none',
          },
          hover: {
            fill: color,
            outline: 'none',
          },
          pressed: {
            fill: color,
            outline: 'none',
          },
        }}
      />

      {geo.properties.shouldShowForCurrentRegion &&
      geo.properties.subregion &&
      subregions?.[geo.properties.subregion as SubregionsType].projects ? (
        <>
          <CustomMarker
            key={geo.rsmKey}
            hover={geo.properties.subregion == hoveredSubregion}
            label={subregions?.[geo.properties.subregion as SubregionsType].projects}
            cords={
              subregions?.[geo.properties.subregion as SubregionsType].cords as unknown as [
                number,
                number,
              ]
            }
          />
        </>
      ) : null}
    </>
  )
}

export default MapGeography
