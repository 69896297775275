import React, { Children } from 'react'
import { ZoomableGroup } from 'react-simple-maps'

import useWindowDimensions from '../../../../shared/hooks/useDimensions'

const ZoomableMobile = ({ children }: { children: React.JSX.Element }) => {
  const windowDimensions = useWindowDimensions()

  return windowDimensions.width < 700 ? (
    <ZoomableGroup zoom={1} center={[0, 0]}>
      {children}
    </ZoomableGroup>
  ) : (
    children
  )
}

export default ZoomableMobile
