import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { Link } from 'react-router-dom'

import useNavigateWithGTM from '../../../../shared/hooks/useNavigateWithGTM'
import styles from './styles.module.scss'

const WorkWithUs = () => {
  const { navigateWithGTM } = useNavigateWithGTM()
  return (
    <div className={styles.block}>
      <Container>
        <Row>
          <Col>
            <h3>Why work with us</h3>
            <div className={styles.content}>
              <Row>
                <Col md={4}>
                  <b>Expert Investigative Interviewing</b>
                </Col>
                <Col md={6} offset={{ md: 1 }}>
                  <p>
                    Our priority is to provide you with the most truthful and up-to-date insights.
                    We leverage investigative interviewing techniques for sourcing information in
                    various situations, which is highly valued by our clients.
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>
                  <b>Reliable Primary Data Sources</b>
                </Col>
                <Col md={6} offset={{ md: 1 }}>
                  <p>
                    Focusing on reliability, we utilise primary datasets that are the result of our
                    own research, not that of others. Comparing data with data, data with source
                    comment, quantitative with qualitative – analysis is as wide-ranging and lateral
                    as we can make it.
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>
                  <b>Tailored Solutions for Every Case</b>
                </Col>
                <Col md={6} offset={{ md: 1 }}>
                  <p>
                    To provide you with the most accurate information, we take great care in
                    consulting sources confidentially, directly, and discreetly. Each case is
                    different yet in all cases we source, corroborate, and analyse our findings as
                    reactively and thoroughly as we can – to give clients a true picture, often in a
                    fast-changing or urgent situation.
                  </p>
                </Col>
              </Row>
            </div>
            <button
              onClick={() =>
                navigateWithGTM('/about', {
                  event: 'meet_our_experts',
                  tagName: 'Button “Meet our experts”',
                })
              }
            >
              MEET OUR EXPERTS
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WorkWithUs
