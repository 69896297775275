import React from 'react'

import styles from './styles.module.scss'

const PreviewImage = ({
  source = require('../../../../public/images/preview.jpg'),
  position = '80%',
}) => {
  return (
    <div className={styles.img}>
      <img
        src={source}
        style={{
          objectPosition: `0 ${position}`,
        }}
      />
    </div>
  )
}

export default PreviewImage
