import React from 'react'
import { Container } from 'react-grid-system'

import HeaderSpacing from '../../shared/components/HeaderSpace/HeaderSpace'
import MiniNavBar from '../../shared/components/Navbar/MiniNavBar'
import Footer from '../Home/components/Footer/Footer'
import styles from './styles.module.scss'

const PrivacyPolicy = () => {
  return (
    <div className={styles.cookies}>
      <MiniNavBar />
      <Container>
        <h1>Privacy Policy</h1>
        <p>
          This privacy policy sets out how urusadvisory.com uses and protects any information that
          you give urusadvisory.com when you use this website.
        </p>

        <h2>Collection of Personal Information</h2>
        <p>
          urusadvisory.com may collect personal information from users in a variety of ways,
          including, but not limited to, when users visit our site, register on the site, fill out a
          form, and in connection with other activities, services, features or resources we make
          available on our site.
        </p>

        <h2>Use of Personal Information</h2>
        <p>
          urusadvisory.com may use personal information to improve our products and services,
          personalise user experience, send periodic emails, or to contact users regarding their
          inquiries or orders.
        </p>

        <h2>Protection of Information</h2>
        <p>
          urusadvisory.com adopts appropriate data collection, storage, and processing practices and
          security measures to protect against unauthorised access, alteration, disclosure, or
          destruction of your personal information, username, password, transaction information, and
          data stored on our site.
        </p>

        <h2>Sharing of Personal Information</h2>
        <p>
          urusadvisory.com does not sell, trade, or rent users' personal identification information
          to others. We may share generic aggregated demographic information not linked to any
          personal identification information regarding visitors and users with our business
          partners, trusted affiliates, and advertisers.
        </p>

        <h2>Third-Party Websites</h2>
        <p>
          Users may find advertising or other content on our site that links to the sites and
          services of our partners, suppliers, advertisers,and sponsors. We do not control the
          content or links that appear on these sites and are not responsible for the practices
          employed by websites linked to or from our site. Browsing and interaction on any other
          website, including websites which have a link to our site, is subject to that website's
          own terms and policies.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          urusadvisory.com has the discretion to update this privacy policy at any time. When we do,
          we will revise the updated date at the bottom of this page. We encourage users to
          frequently check this page for any changes to stay informed about how we are helping to
          protect the personal information we collect.
        </p>

        <h2>Your Acceptance of These Terms</h2>
        <p>
          By using this site, you signify your acceptance of this policy. If you do not agreewith
          this policy, please do not use oursite. Your continued use of the site following the
          posting of changes to this policy will be deemed your acceptance of those changes.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, the practices of this site, or your
          dealings with this site, please contact us at{' '}
          <a href="mailto:admin@urusadvisory.com">admin@urusadvisory.com</a>.
        </p>

        <p>This Privacy Policy was last updated in 2023.</p>
        <br />
        <br />
      </Container>
      <Footer isMinimized />
    </div>
  )
}

export default PrivacyPolicy
