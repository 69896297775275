import React, { useEffect } from 'react'

import HeaderSpacing from '../../shared/components/HeaderSpace/HeaderSpace'
import Header from '../../shared/components/Header_old/Header'
import TextBubbleSubPages from '../../shared/components/TextBubble/TextBubbleSubPages'
import { pushToGTM } from '../../shared/utis/gtm'
import Footer from '../Home/components/Footer/Footer'
import Form from '../Home/components/Form/Form'
import Bubble from './components/Bubble/Bubble'
// import SpecialBlog1 from "./components/SpecialBlog/SpecialBlogNoPhoto"
import SpecialBlog from './components/SpecialBlog/SpecialBlogNoPhoto'

const Service = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      // @ts-ignore
      behavior: 'instant',
    })
  }, [])

  return (
    <>
      <HeaderSpacing />
      <Header
        headerText={`Integrity Due Diligence \n& Background Screening`}
        subHeaderText="Have complete confidence in your counterparties and stakeholders"
        headerWrapperStyles={{
          background: '#191736',
        }}
        onRequestClick={() => {
          pushToGTM({
            event: 'request_service',
            tagName: '“Request a service” button (Integrity Due Diligence)',
          })
        }}
        buttonText={'Request a service '}
        buttonStyles={{
          fontWeight: 700,
        }}
      />
      <TextBubbleSubPages
        leftSection={
          <>
            <h3
              style={{
                textAlign: 'left',
              }}
            >
              All-inclusive Integrity Due Diligence & Background Screening Services
            </h3>
            <p>
              Depending on your industry, Due Diligence & Background Screening may encompass a range
              of services. For instance, industries such as financial and legal services will
              typically require more extensive screening compared to retail or sales positions.
            </p>
            <p>
              By adopting a comprehensive approach, you can ensure that you have a complete picture
              of the candidate, partner or any other third party and their history, this at any
              stage of your trade relationship or investment. This may involve conducting background
              checks and verifying educational qualifications, professional licenses, and criminal
              records.
            </p>
            <p>
              Additionally, you may want to conduct executive and board-level due diligence, vendor
              and supplier screening, anti-bribery and corruption screening, regulatory compliance
              screening, reputation analysis, and cybersecurity screening. These services can help
              you make informed hiring, trade or investment decisions and minimise the risk of any
              negative impact on your organisation.
            </p>
          </>
        }
        rightTitle={'The services available for our clients are:'}
        direction={'column'}
        bubbleItems={[
          'Vendor and supplier screening',
          'Reputation analysis',
          'Employment background checks',
          'Anti-bribery and corruption screening',
          'Executive and board-level due diligence',
        ]}
      />
      <Bubble
        direction={'column'}
        styleType="points"
        title="Why choose Urus Advisory to conduct your Integrity Due Diligence & Background Screenings?"
        bubbleItems={[
          {
            title: 'On-the-Ground Presence',
            description:
              'Our physical presence in the countries we operate in allows us to have a better understanding of local landscapes with their cultural, market trends and screening variations.',
          },
          {
            title: 'Unmatched Local Knowledge',
            description:
              'We  offer you  extensive knowledge of the information that can be obtained from open sources, and our network enables us to acquire valuable insights by directly engaging with local stakeholders.',
          },
          {
            title: 'Technological Advantage',
            description:
              'By leveraging our expertise and AI-powered technology, we are able to deliver a complete and accurate picture of the subject of the investigation.',
          },
          {
            title: 'International Orientation',
            description:
              'Over the past decade, we have garnered extensive experience in the Due Diligence & Background Screening field, serving a wide range of clients from Europe, the USA, the UK and CIS, and delivering over 5,000 successful projects.',
          },
        ]}
      />
      <SpecialBlog
        quote={
          'Conducting a thorough executive background check can be the difference between success and failure in the business world. It can mean the difference between working with a trustworthy and reliable team or facing months or even longer recovering from the consequences of reputational damage and loss of brand trust.'
        }
        author={'Alexei S - Head of Background Screening'}
      />
      <Form
        serviceOfInterest="Integrity Due Diligence & Background Screening"
        afterSubmit={() => {
          pushToGTM({
            event: 'contact_form_submit',
            tagName: 'Contact form submission “Contact our team today” (Integrity Due Diligence)',
          })
        }}
      />
      <Footer isMinimized={false} />
    </>
  )
}

export default Service
