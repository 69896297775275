import { useCallback } from 'react'
import { useNavigate } from 'react-router'

import { DataLayerGTMPropsType, pushToGTM } from '../utis/gtm'

const useNavigateWithGTM = () => {
  const navigate = useNavigate()
  const navigateToExternalResourceGTM = useCallback(
    (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      link: string,
      data?: DataLayerGTMPropsType
    ) => {
      e.preventDefault()
      if (data) {
        pushToGTM({
          ...data,
        })
      }

      window.open(link)
    },
    []
  )

  const navigateWithGTM = useCallback((link: string, data?: DataLayerGTMPropsType) => {
    if (data) {
      pushToGTM({
        ...data,
      })
    }

    navigate(link)
  }, [])
  return {
    navigateWithGTM,
    navigateToExternalResourceGTM,
  }
}

export default useNavigateWithGTM
