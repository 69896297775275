import React from 'react'
import { Col, Container, Row } from 'react-grid-system'

import styles from './styles.module.scss'

const TextBubbleNew = (props: {
  leftSection: JSX.Element
  isBlue?: boolean
  rightTitle?: string
  isHrEnable?: boolean
  bubblesRight?: boolean
  direction: 'row' | 'column'
  bubbleItems: string[]
  styleType?: 'bubble' | 'points'
}) => {
  const { styleType = 'bubble', isHrEnable = true, isBlue = false, bubblesRight = true } = props
  return (
    <div className={styles.bubbles_dots}>
      <div
        style={{
          background: !isBlue ? '#fff' : '#14346F',
        }}
        className={styles.section}
      >
        <Container
          style={{
            maxWidth: 1156.7,
          }}
        >
          <Row
            style={{
              alignItems: 'stretch',
            }}
          >
            <Col
              lg={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '0 !important',
              }}
            >
              <div
                style={{
                  color: !isBlue ? 'black' : '#fff',
                }}
                className={`${styles.left_section} ${styles[styleType + '_section']} ${styles[(props.direction ?? 'row') + '_left_section']}`}
              >
                <h3>... by contextualising the micro and placing it into the macro</h3>
                {/* <br /> */}
                <p className={styles.paragraph}>
                  Evolving regulatory standards require modern approaches and the use of new
                  technologies. When you work with us, you get a complete picture, powered by the
                  best tools, many developed in-house.
                </p>
                <p className={styles.paragraph}>
                  By tapping into our <b>human talent and AI resources,</b> you can connect
                  previously unlinked information from validated sources: legal documents,
                  regulatory filings, public records, interviews, reference checks, and other
                  relevant data points.
                </p>
              </div>
            </Col>
            <Col
              lg={6}
              className={styles.right_section_wrapper}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                className={`${styles.right_section} ${styles[styleType + '_right_section']} ${styles[(props.direction ?? 'row') + '_right_section']}`}
              >
                <h4
                  className={`${styles[(props.direction ?? 'row') + '_title']} ${styles[styleType + '_title']}`}
                  style={{}}
                >
                  <span>{props.rightTitle}</span>
                </h4>
                <div
                  className={`${styles.bubble_container} ${styles[props.direction ?? 'row']} ${styles[styleType + '_container']}`}
                >
                  {props.bubbleItems.map((item, index) => {
                    return (
                      <div key={item + index} className={styles.column}>
                        <div className={`${styles.item} ${styles[styleType]}`}>
                          <span className={styles.circle}></span>
                          <span
                            className={styles.item_text}
                            style={{
                              ...(isBlue &&
                                styleType == 'bubble' && {
                                  background: '#EC5922',
                                }),
                            }}
                          >
                            {`${item}`}
                          </span>
                        </div>
                        {props.bubbleItems.length - 1 != index && <span className={styles.line} />}
                      </div>
                    )
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default TextBubbleNew
