import TagManager from 'react-gtm-module'

export type DataLayerGTMPropsType = {
  event: string
  tagName?: string
  title?: string
}

export const pushToGTM = (dataLayer: Partial<DataLayerGTMPropsType>) => {
  const newGTMParams = {
    ...dataLayer,
  }
  TagManager.dataLayer({
    dataLayer: newGTMParams,
  })
  console.log('event_triggered', newGTMParams)
}
